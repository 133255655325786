<template>
  <v-app>
      <v-system-bar></v-system-bar>
      <AppBarStep :title="title"/>
     
      <router-view></router-view>
  </v-app>
</template>

<script>
import AppBarStep from "@/components/form/AppBarStep";


export default {
  components: {
    AppBarStep,
  },
  data() {
    return {
      loading: false,
      form: null,
      title: null
    }
  },
  methods: {
     fetchData() {
      this.loading = true;
      this.form = this.$offlineStorage.get("currentForm")
      if(this.form.formTypeName){
        this.title = this.form.formTypeName
      }else{
        this.title = this.form.arrResult[0].formTypeName
      }
      this.loading = false;
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
}
</script>

<style>

</style>